<!-- @Author: Yu_Bo -->
<template>
    <div class='my_sound'>
        <!--  -->
        <div class="tem_nav">
            <div class="left_nav">
                <div class="nav" :class="modelIndex == item.type ? 'nav_active' : ''" v-for="(item, index) in modelList"
                    :key="index" @click="modelNavBtn(item.type)">
                    <span class="name">{{ item.name }}</span>
                    <span class="xian"></span>
                </div>
            </div>
            <div class="right_text">
                <div class="t_input">
                    <el-input v-model="inputTxt" size='mini' @keyup.enter.native="soundListBtn" @clear='soundListBtn'
                        :clearable="true" prefix-icon='el-icon-search' placeholder="搜索模板关键字"></el-input>
                </div>
            </div>
        </div>
        <!--  -->
        <div class="sound_list" v-loading='loading'>
            <div class="no_html" v-if="list.length == 0">
                <img src="@/assets/images/no_html/no_img_b.png" alt="">
                <div class="txt">您最近还没有使用过TTS，请在“声音市场”中挑选使用</div>
                <div class="btn"><span @click="digitalBtn">前往声音市场</span></div>
            </div>
            <template v-else>
                <div class="list" v-for="(item, index) in list" :key="index" @click="listBtn(item.tts_list_type, item.id)">
                    <div class="right_top color1" v-if="item.tts_list_type == '限时免费'">限时免费</div>
                    <div class="right_top color2" v-if="item.tts_list_type == '定制'">定制</div>
                    <div class="right_top color3" v-if="item.tts_list_type == '审核未通过'">审核未通过</div>
                    <div class="right_top color7" v-if="item.tts_list_type == '审核通过'">审核通过</div>
                    <div class="right_top color3" v-if="item.tts_list_type == '训练失败'">训练失败</div>
                    <div class="right_top color4" v-if="item.tts_list_type == '已购'">已购</div>
                    <div class="right_top color5" v-if="item.tts_list_type == '训练中'">训练中</div>
                    <div class="right_top color6" v-if="item.tts_list_type == '审核中'">审核中</div>
                    <div class="list_info">
                        <div class="img" @mouseenter="enterBtn(item.id, item.audio_url)" @mouseleave="leaveBtn">
                            <el-image style="width: 100%; height: 100%" :src="item.avatar" fit="cover"></el-image>
                            <div class="bg_color" v-if="item.id == playId">
                                <img src="@/assets/images/work/play_sound.gif" alt="">
                                <audio :src="item.audio_url" loop='true' autoplay="autoplay" hidden></audio>
                            </div>
                        </div>
                        <div class="text">
                            <div class="name one-txt-cut">{{ item.name }}</div>
                            <div class="txt one-txt-cut">{{ item.label }}</div>
                            <div class="btn">
                                <div class="right_btn"
                                    v-if="item.tts_list_type == '限时免费' || item.tts_list_type == '定制' || item.tts_list_type == '审核通过' || item.tts_list_type == '已购'"
                                    @click="tomake(item)">
                                    引用<i class="el-icon-arrow-right"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="list">
                    <img class="list_bg" src="@/assets/images/work/sound_bg.png" alt="">
                    <div class="list_tip" @click="digitalBtn">
                        前往声音市场，查看更多<i class="el-icon-arrow-right"></i>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            modelIndex: 1,
            modelList: [
                { name: '最近使用', type: 1, },
                { name: '已购', type: 2, },
                { name: '定制', type: 3, },
                { name: '男声', type: 4, },
                { name: '女声', type: 5, },
                { name: '童声', type: 6, },
            ],
            inputTxt: '',
            //
            list: [],
            page: 1,
            limit: 10,
            loading: false,
            //
            playId: 'none'
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 列表
        getList() {
            var that = this
            var parasm = {
                keyword: that.inputTxt,
                page: that.page,
                limit: that.limit
            }
            that.loading = true
            if (that.modelIndex == 1 || that.modelIndex == 4 || that.modelIndex == 5 || that.modelIndex == 6) {
                // 最近使用
                var arr = that.modelList.find(item => item.type == that.modelIndex)
                var obj = {
                    ...parasm,
                    label: that.modelIndex == 1 ? '' : arr.name,
                }
                that.$workbenApi.myVoice(obj).then(res => {
                    that.loading = false
                    if (res.code == 1000) {
                        that.list = res.result ? res.result.data : []
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            } else if (that.modelIndex == 2) {
                // 已购
                that.$workbenApi.memberTtsIndex(parasm).then(res => {
                    that.loading = false
                    if (res.code == 1000) {
                        that.list = res.result ? res.result.data : []
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            } else if (that.modelIndex == 3) {
                // 定制
                that.$workbenApi.ttsListIndex(parasm).then(res => {
                    that.loading = false
                    if (res.code == 1000) {
                        that.list = res.result ? res.result.data : []
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 切换
        modelNavBtn(index) {
            this.modelIndex = index
            this.page = 1
            this.getList()
        },
        //
        soundListBtn() {
            this.page = 1
            this.getList()
        },
        enterBtn(val, audio_url) {
            if (audio_url) {
                this.playId = val
            }
        },
        leaveBtn() {
            this.playId = 'none'
        },
        // 前往声音市场
        digitalBtn() {
            this.$router.push({
                path: '/digital/index',
                query: {
                    type: 4
                }
            })
        },
        //
        listBtn(txt, vid) {
            if (txt == '训练失败' || txt == '审核未通过') {
                this.$router.push('/workben/sound_edit/' + vid)
            }
        },
        // 去制作
        tomake(item) {
            var that = this
            var params = {
                tts_list_id: item.id,
                order_source: 1
            }
            that.$audioApi.quickCreate(params).then(res => {
                if (res.code == 1000) {
                    that.$router.push({
                        path: '/createaudio/index/' + res.result.id,
                        query: {
                            name: res.result.name
                        }
                    })
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
    },
}
</script>

<style lang='scss' scoped>
.my_sound {
    width: 100%;
    margin-top: 25px;

    .tem_nav {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .left_nav {
            display: flex;

            .nav {
                cursor: pointer;
                position: relative;
                width: 104px;
                height: 50px;
                background: rgba(255, 255, 255, 0.7);

                .name {
                    display: block;
                    width: 100%;
                    line-height: 50px;
                    text-align: center;
                    font-size: 16px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }
            }

            .nav_active {
                background: rgba(255, 255, 255, 0.47);

                .name {
                    color: #2E4BF2;
                }

                .xian {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    display: block;
                    width: 25px;
                    height: 2px;
                    margin-left: -13px;
                    background: #2E4BF2;
                }
            }

            .nav:first-child {
                border-radius: 10px 0px 0px 0px;
            }

            .nav:last-child {
                border-radius: 0px 10px 0px 0px;
            }
        }

        .right_text {
            display: flex;
            align-items: center;

            .t_input {
                width: 186px;
            }
        }
    }

    /*  */
    .sound_list {
        width: 100%;
        padding-top: 20px;
        border-radius: 0 10px 10px;
        background: rgba(255, 255, 255, 0.47);
        display: flex;
        flex-wrap: wrap;

        .list {
            cursor: pointer;
            margin-bottom: 20px;
            margin-left: 30px;
            position: relative;
            width: 355px;
            height: 124px;
            border-radius: 10px;
            background: #fff;
            overflow: hidden;

            .right_top {
                position: absolute;
                top: 0;
                right: 0;
                z-index: 1;
                padding: 0 10px;
                height: 20px;
                line-height: 20px;
                border-radius: 0px 10px 0px 10px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
            }

            .color1 {
                color: #2E4BF2;
                background: rgba(46, 113, 242, 0.14);
            }

            .color2 {
                color: #774501;
                background: rgba(255, 192, 77, 1);
            }

            .color3 {
                color: #fff;
                background: rgba(255, 77, 77, 1);
            }

            .color4 {
                color: #F28A2E;
                background: rgba(242, 136, 46, 0.14);
            }

            .color5 {
                color: #999;
                background: rgba(0, 0, 0, 0.14);
            }

            .color6 {
                color: #7D2EF2;
                background: rgba(156, 46, 242, 0.14);
            }

            .color7 {
                color: #59CB58;
                background: rgba(69, 242, 46, 0.14);
            }

            .list_info {
                width: 100%;
                height: 100%;
                padding: 22px 0px 22px 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .img {
                    position: relative;
                    width: 80px;
                    height: 80px;
                    border-radius: 50%;
                    overflow: hidden;

                    .bg_color {
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        display: none;
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                        background: rgba(46, 75, 242, 0.3);
                        align-items: center;
                        justify-content: center;

                        img {
                            display: block;
                            width: 60px;
                            height: 60px;
                        }
                    }
                }

                .img:hover {
                    .bg_color {
                        display: flex;
                    }
                }

                .text {
                    padding-left: 20px;
                    width: 255px;
                    height: 80px;
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;

                    .name {
                        font-size: 16px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                    }

                    .txt {
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                    }

                    .btn {
                        display: flex;
                        justify-content: flex-end;

                        .right_btn {
                            padding: 0 5px 0 10px;
                            height: 21px;
                            line-height: 21px;
                            background: #2E4BF2;
                            border-radius: 10px 0 0 10px;
                            font-size: 12px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #FFFFFF;
                        }
                    }
                }
            }

            .list_bg {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                display: block;
                width: 100%;
                height: 100%;
            }

            .list_tip {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 2;
                display: block;
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;

                .el-icon-arrow-right {
                    margin-top: 2px;
                }
            }
        }
    }
}
</style>
