<!-- @Author: Yu_Bo -->
<template>
    <div class='video_box'>
        <div class="video_box_main" v-if="dialogVisible">
            <div class="main_video">
                <video ref="videoPlayer" :src="videoUrl" controls="controls" autoplay="autoplay" loop="loop"
                    controlslist="nodownload noremoteplayback noplaybackrate" disablepictureinpicture></video>
                <div class="v_close">
                    <div class="close" @click="closeBtn"><i class="el-icon-close"></i></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            dialogVisible: false,
            videoUrl: '',
        }
    },
    computed: {},
    watch: {},
    created() { },
    mounted() { },
    methods: {
        // 打开
        openDialog(url) {
            this.videoUrl = url
            this.dialogVisible = true
        },
        // 关闭
        closeBtn() {
            this.dialogVisible = false
            this.$refs.videoPlayer.pause();//暂停
            // this.$refs.videoPlayer.play();//播放
        },
    },
}
</script>

<style lang='scss' scoped>
.video_box {
    width: 100%;

    .video_box_main {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 1002;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);

        .main_video {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            video {
                max-width: 70%;
                max-height: 80%;
            }

            .v_close {
                height: 100%;
                max-height: 80%;
                padding-left: 30px;

                .close {
                    cursor: pointer;
                    width: 40px;
                    height: 40px;
                    font-size: 24px;
                    color: #fff;
                    background: rgba(0, 0, 0, 0.3);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;

                    .el-icon-close {
                        font-size: 26px;
                    }
                }
            }
        }
    }
}
</style>
