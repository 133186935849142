<!-- @Author: Yu_Bo -->
<template>
    <div class='works_video' v-loading='loading'>
        <div class='list_left' @click="createBtn">
            <img src="@/assets/images/work/btn_icon.png" alt="">
            <span>快速创建</span>
        </div>
        <div class='list_right scroll_bar_x'>
            <div class="list_no_data" v-if="worksList.length == 0">您还没有作品哦，快速创建一下吧～</div>
            <template v-else>
                <div class="right_text" v-for="(item, index) in worksList" :key='index'>
                    <div class="t_video" v-if="item.status == 5">
                        <video v-show="item.show" :src="item.video_url" :ref="'tempVideo' + index" controls="controls" @ended="videoEndedBtn(index)" @pause="videoPauseBtn(index)" controlslist="nodownload noremoteplayback noplaybackrate" disablepictureinpicture></video>
                        <el-image v-show="!item.show" :src="item.video_img" fit="scale-down"></el-image>
                        <div class='right_top' v-show="!item.show">封面</div>
                        <div class="play_btn" v-show="!item.show" @click="playBtn(index)">
                            <img src="@/assets/images/icon/play.png" alt="">
                        </div>
                    </div>
                    <div class="t_image" v-else>
                        <el-image v-if="item.video_img" :src="item.video_img" fit="scale-down"></el-image>
                        <div class='left_top top_right_color_edit' v-if="item.status == 1">编辑中</div>
                        <div class='left_top top_right_color_conflate' v-if="item.status == 2">合成中</div>
                        <div class='left_top top_right_color_lineUp' v-if="item.status == 3">排队中</div>
                        <div class='left_top top_right_color_fail' v-if="item.status == 4">创作失败</div>
                        <div class='mild_btn' v-if="item.status == 1 || item.status == 4" @click="goEdit(item)">去编辑<i class="el-icon-arrow-right"></i>
                        </div>
                    </div>
                    <div class='t_info'>
                        <div class="info_left">
                            <div class="name">
                                <div class="name_input" v-if="item.inputShow">
                                    <el-input :key="index" size="mini" :ref="'input' + index" :autofocus="true" v-model="item.name" @keyup.enter.native="$event.target.blur()" @blur='blurBtn($event, index)' placeholder="请输入视频名称"></el-input>
                                </div>
                                <div class='name_txt one-txt-cut' v-else>{{ item.name }}</div>
                                <div class='name_icon' v-if="!item.inputShow" @click="nameInputBtn(index)">
                                    <i class="el-icon-edit-outline"></i>
                                </div>
                            </div>
                            <div class='time'>创建时间 {{ item.create_time }}</div>
                        </div>
                        <div class="info_right">
                            <el-popover placement="bottom-end" width="145" trigger="hover" popper-class="works_right-btn">
                                <div class="content">
                                    <div class="item">复制并编辑</div>
                                    <div class="item" v-if="item.status == 5">复制链接</div>
                                    <div class="item" v-if="item.status == 5">设置封面</div>
                                    <div class="item" v-if="item.status == 5">下载</div>
                                    <div class="item c_e" @click="delBtn(item.id)">删除</div>
                                </div>
                                <i slot="reference" class="el-icon-more"></i>
                            </el-popover>
                        </div>
                    </div>
                </div>
            </template>
        </div>
        <!--  -->
        <point-out ref='dialogTip' @close='closeBtn' type='works' dialogTitle="提示" dialogWidth='380px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
            <div class="zc_dialog_tip_content">
                <div class="text">作品删除后不可恢复，请确认是否删除？</div>
            </div>
        </point-out>
    </div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue'//温馨提示弹框
export default {
    components: { PointOut },
    props: {},
    data() {
        return {
            loading: false,
            worksList: [],
            //
            del_id: ''
        }
    },
    computed: {},
    watch: {},
    created() {
        this.getList()
    },
    mounted() { },
    methods: {
        // 列表
        getList() {
            var that = this
            that.loading = true
            that.$workbenApi.worktableIndex().then(res => {
                that.loading = false
                if (res.code == 1000) {
                    if (res.result.total) {
                        var arr = res.result.data
                        arr.forEach(item => {
                            item.inputShow = false
                            item.show = false
                        })
                        that.worksList = arr
                    }
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 播放
        playBtn(index) {
            this.worksList.forEach((v, i) => {
                if (index == i) {
                    v.show = true
                    let refPlay = eval('this.$refs.tempVideo' + index)[0]
                    refPlay.play()//播放
                } else if (v.status == 5) {
                    let refPause = eval('this.$refs.tempVideo' + i)[0]
                    refPause.pause()//暂停
                    v.show = false
                }
            })
        },
        // 暂停所有
        pauseBtn() {
            this.worksList.forEach((v, i) => {
                if (v.status == 5) {
                    v.show = false
                    let ref = eval('this.$refs.tempVideo' + i)[0]
                    ref.pause()//暂停
                }
            })
        },
        // 视频播放暂停
        videoPauseBtn(index) {
            this.worksList[index].show = false
        },
        // 视频播放结束
        videoEndedBtn(index) {
            this.worksList[index].show = false
        },
        // 修改名称
        nameInputBtn(index) {
            var that = this
            this.worksList.forEach((v, i) => {
                if (i == index) {
                    v.inputShow = true
                } else {
                    v.inputShow = false
                }
            })
            that.$nextTick(() => {
                let ref = eval('that.$refs.input' + index)[0]
                ref.focus()
            })
            this.$forceUpdate()
        },
        blurBtn(event, index) {
            var that = this
            that.worksList.forEach((v, i) => {
                v.inputShow = false
            })
            var params = {
                name: that.worksList[index].name
            }
            that.$creationApi.editVideoName(params, that.worksList[index].id).then(res => {
                if (res.code == 1000) {
                    that.$succMsg(res.message)
                    that.$forceUpdate()
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 删除
        delBtn(val) {
            this.del_id = val
            this.$refs.dialogTip.openDialogBtn()
        },
        closeBtn() {
            var that = this
            that.$creationApi.delVideo(that.del_id).then(res => {
                if (res.code == 1000) {
                    that.getList()
                } else {
                    that.$errMsg(res.message)
                }
            })
        },
        // 创建
        createBtn() {
            let str = {
                template_from: 1,
                aspect_ratio: 2,
                fps: 25,
                bit_rate: '3M',
                resolution: '1080*1920',
                config: [
                    {
                        id: new Date().getTime(),
                        data: {
                            ttsAudio: "",
                            content: '',
                        },
                        scenes_conf: [],
                        background: {
                            type: "",
                            url: "",
                            objectFit: "cover",
                            color: ""
                        }
                    }
                ],
            }
            let obj = {
                template_from: 1,
                aspect_ratio: 2,
                config: JSON.stringify(str),
                bit_rate: '3M',
                resolution: '1920*1080'
            }

            this.$digitalApi.creatCard(obj).then(res => {
                if (res.code == 1000) {
                    this.$router.push({
                        path: '/createvideo/index/' + res.result.id,
                        query: {
                            name: '新建视频'
                        },
                    })
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        goEdit(item) {
            this.$router.push({
                path: '/createvideo/index/' + item.id,
                query: {
                    name: item.name
                },
            })
        },
    },
}
</script>

<style lang='scss' scoped>
.works_video {
    width: 100%;
    padding-top: 20px;
    display: flex;

    .list_left {
        cursor: pointer;
        width: 224px;
        height: 162px;
        margin-right: 30px;
        background: rgba(66, 94, 255, 0.08);
        border-radius: 10px;
        border: 1px dashed #2e4bf2;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        img {
            display: block;
            width: 56px;
            height: 56px;
        }

        span {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2e4bf2;
        }
    }

    .list_right {
        width: calc(100% - 254px);
        height: 230px;
        overflow-x: auto;
        display: flex;

        .list_no_data {
            width: 100%;
            height: 162px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .right_text {
            cursor: pointer;
            min-width: 286px;
            height: 100%;
            margin-right: 30px;

            .t_video {
                position: relative;
                width: 100%;
                height: 162px;
                border-radius: 10px;
                background: #111111;
                overflow: hidden;

                video {
                    display: block;
                    width: 100%;
                    height: 100%;
                }

                .el-image {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                }

                .right_top {
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 2;
                    padding: 0 5px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #2e4bf2;
                    border-radius: 0px 10px 0px 10px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                }

                .play_btn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 3;
                    width: 40px;
                    height: 40px;
                    margin-top: -20px;
                    margin-left: -20px;
                    /* background: rgba(255,255,255,0.5); */
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        display: block;
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .t_image {
                position: relative;
                width: 100%;
                height: 162px;
                border-radius: 10px;
                background: #111111;
                overflow: hidden;

                .el-image {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                }

                .left_top {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                    padding: 0 5px;
                    height: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #612ef2;
                    border-radius: 10px 0px 10px 0px;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                }

                .mild_btn {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    z-index: 3;
                    width: 68px;
                    height: 32px;
                    margin-top: -16px;
                    margin-left: -34px;
                    background: linear-gradient(
                        90deg,
                        #4875ff 0%,
                        #2b4afe 100%
                    );
                    border-radius: 4px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 12px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;

                    .el-icon-arrow-right {
                        margin-top: 2px;
                        margin-left: 2px;
                    }
                }
            }

            .t_info {
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .info_left {
                    width: 250px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    flex-direction: column;

                    .name {
                        width: 100%;
                        height: 28px;
                        line-height: 28px;
                        font-size: 14px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #333333;
                        display: flex;
                        align-items: center;

                        .name_input {
                            width: 230px;

                            ::v-deep .el-input__inner {
                                padding: 0 10px;
                                background: rgba(46, 75, 242, 0.06);
                                border-radius: 4px;
                                border: 1px solid #2e4bf2;
                            }

                            ::-webkit-input-placeholder {
                                /* WebKit, Blink, Edge */
                                color: #666666;
                            }

                            :-moz-placeholder {
                                /* Mozilla Firefox 4 to 18 */
                                color: #666666;
                            }

                            ::-moz-placeholder {
                                /* Mozilla Firefox 19+ */
                                color: #666666;
                            }

                            :-ms-input-placeholder {
                                /* Internet Explorer 10-11 */
                                color: #666666;
                            }
                        }

                        .name_txt {
                            max-width: 200px;
                            font-size: 14px;
                            font-family: PingFangSC-Regular, PingFang SC;
                            font-weight: 400;
                            color: #333333;
                        }

                        .name_icon {
                            margin-left: 10px;
                        }
                    }

                    .time {
                        width: 100%;
                        font-size: 12px;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #666666;
                    }
                }

                .info_right {
                    height: 100%;
                    width: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .el-icon-more {
                        font-size: 18px;
                        color: #333333;
                        transform: rotate(90deg);
                    }
                }
            }
        }

        .right_text:last-child {
            margin-right: 20px;
        }
    }
}
</style>
