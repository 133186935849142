<!-- @Author: Yu_Bo -->
<template>
	<div class='my_book'>
		<!--  -->
		<div class="model_nav">
		  <div class="nav" :class="modelIndex==item.type?'nav_active':''" v-for="(item,index) in modelList" :key="index" @click="modelNavBtn(item.type)">
		    <span class="name">{{item.name}}</span>
		    <span class="xian"></span>
		  </div>
		</div>
    <!--  -->
    <div class="book_list">
      <!--  -->
      <div class="box_ss">
        <div class="box_top">
          <div class="book_nav">
            <div class="nav" :class="navIndex==index?'nav_active':''" v-for="(item,index) in navList" :key="index" @click="navBtn(index)">{{item.name}}</div>
          </div>
          <div class="right">
            <div class="t_input">
              <el-input v-model="inputTxt" size='mini' @keyup.enter.native="bookListBtn" @clear='bookListBtn' :clearable="true" prefix-icon='el-icon-search' placeholder="搜索模板关键字"></el-input>
            </div>
            <el-dropdown @command="bookBtn">
              <el-button type="primary" size="mini">
                <i class="el-icon-plus"></i>新建台本
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item :command='1'>新建空白台本</el-dropdown-item>
                <el-dropdown-item :command='2'>新建台本专辑</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="next_list" v-if="nextList.length">
          <div class="next_nav" :class="nextIndex===index?'next_active':''" v-for="(item,index) in nextList" :key="index" @click="nextBtn(index)">{{item.name}}</div>
        </div>
      </div>
      <!--  -->
      <div v-loading='loading'>
        <div class="no_html" v-if="list.length==0">
          <img src="@/assets/images/no_html/no_img_b.png" alt="">
          <div class="txt">你还没有“台本”，请前往“台本市场”挑选哦~</div>
          <div class="btn"><span @click="bookGoBtn">前往台本市场</span></div>
        </div>
        <div class="book_info" v-else>
          <div class="left">
            <div class="left_btn1" @click="bookUrlBtn">
              <img src="@/assets/images/work/book_icon1.png" alt="">
              <span>链接转台本</span>
            </div>
            <div class="left_btn2" @click="bookVideoBtn">
              <img src="@/assets/images/work/book_icon2.png" alt="">
              <span>视频转台本</span>
            </div>
          </div>
          <div class="right">
            <div class="list" v-for="(item,index) in list" :key="index">
              <div class="img">
                <el-image style="width: 100%; height: 100%" :src="item.cover_url" fit="cover"></el-image>
                <div class="icon" v-if="item.is_album==1">
                  <img src="@/assets/images/work/book_icon3.png" alt="">
                </div>
                <div class="icon2" v-if="item.sound_url">
                  <img src="@/assets/images/work/book_icon4.png" alt="">
                </div>
              </div>
              <div class="list_info">
                <div class="info_name one-txt-cut">{{item.name}}</div>
                <div class="info_tip one-txt-cut">作者：{{item.author || '--'}}</div>
                <div class="info_btn">
                  <img v-if="modelIndex==1" @click="delBtn(item.id)" src="@/assets/images/work/book_icon5.png" alt="">
                  <img @click="editBtn(item.id,item.is_album)" src="@/assets/images/work/book_icon6.png" alt="">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="page_box" v-if="total" style="padding-top: 20px;">
          <paging-page :total='total' :pageSizes='pageSizes' align='right' @sizeChange='sizeChangeBtn' @currentChange='currentChangeBtn'></paging-page>
        </div>
      </div>
    </div>
    <!-- 链接转台本 -->
    <book-link ref="linkBook"></book-link>
    <!-- 视频转台本 -->
    <book-video ref="videoBook"></book-video>
    <!-- 温馨提示 -->
    <point-out ref='dialogTip' dialogTitle="提示" type="book" @close='closeListBtn' dialogWidth='420px' :iconShow='true' iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
      <div class="zc_dialog_tip_content">
        <div class="text">作品删除后不可恢复，请确认是否删除</div>
      </div>
    </point-out>
	</div>
</template>

<script>
  import PagingPage from '@/components/paging_page/paging_page.vue'//分页
  import BookLink from '@/components/book_page/book_link.vue'//链接转台本
  import BookVideo from '@/components/book_page/book_video.vue'//视频转台本
  import PointOut from '@/components/point_out/point_out.vue'//温馨提示弹框
	export default {
		components: {PagingPage,BookLink,BookVideo,PointOut},
		props: {},
		data() {
			return{
        modelIndex:1,
        modelList:[
          {name:'最近使用',type:1,},
          {name:'已购买',type:2,},
        ],
        navIndex:0,
        navList:[],
        nextIndex:'',
        nextList:[],
        inputTxt:'',
        list:[],
        //
        page: 1,
        limit: 10,
        total: 0,
        pageSizes:[12,24,36],
        loading: false,
        catId:"",
        //
        del_id:"",
			}
		},
		computed: {},
		watch: {},
		created() {
      this.getParams()
    },
		mounted() {},
		methods: {
      // 获取导航分类
      getParams(){
        var that = this
        that.$workbenApi.bookParams().then(res => {
          if (res.code == 1000) {
            that.navList=res.result
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 列表
      getList(){
        var that = this
        that.loading=true
        var parasm = {
          market_script:that.modelIndex,
          name:that.inputTxt,
          queryType:that.navList[that.navIndex].queryType,
          cat_id:that.catId,
          page:that.page,
          limit:that.limit
        }
        that.$workbenApi.memberScript(parasm).then(res => {
          that.loading=false
          if (res.code == 1000) {
            that.list=res.result.data
            that.total=res.result.total
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 切换
      modelNavBtn(index){
        this.modelIndex=index
        this.nextIndex=''
        this.catId=''
        this.page=1
        this.getList()
      },
      // 切换
      navBtn(index){
        this.navIndex=index
        if(this.navList[index].id){
          this.catId=this.navList[index].id
          this.nextParams(this.navList[index].id)
        }else{
          this.nextList=[]
          this.nextIndex=''
          this.catId=''
        }
        this.page=1
        this.getList()
      },
      // 获取下级分类
      nextParams(val){
        var that = this
        var params = {
          parent_id:val
        }
        that.$workbenApi.bookCatList(params).then(res => {
          if (res.code == 1000) {
            that.nextList=res.result
          } else {
            that.$errMsg(res.message)
          }
        })
      },
      // 切换
      nextBtn(index){
        this.nextIndex=index
        this.catId=this.nextList[index].id
        this.page=1
        this.getList()
      },
      // 新建台本
      bookBtn(val){
        if(val==1){
          this.$router.push('/workben/drama/-1')
        }else if(val==2){
          this.$router.push('/workben/drama_album/-1')
        }
      },
      // 编辑台本
      editBtn(vid,type){
        if(type==1){
          // 专辑
          this.$router.push({
            path:'/workben/drama_album/'+vid,
          })
        }else{
          // 台本
          this.$router.push({
            path:'/workben/drama/'+vid,
          })
        }
      },
      // 改变每页条数
      sizeChangeBtn(val){
        this.limit = val
        this.page = 1
        this.getList()
      },
      // 改变页数
      currentChangeBtn(val){
        this.page = val
        this.getList()
      },
      bookListBtn(){
        this.page = 1
        this.getList()
      },
      // 链接转台本
      bookUrlBtn(){
        this.$refs.linkBook.openDialog()
      },
      // 视频转台本
      bookVideoBtn(){
        this.$refs.videoBook.openDialog()
      },
      // 前往台本市场
      bookGoBtn(){
        this.$router.push('/workben/tiben_market')
      },
      // 删除台本
      delBtn(val){
        this.del_id=val
        this.$refs.dialogTip.openDialogBtn()
      },
      closeListBtn(){
        var that = this
        that.loading=true
        that.$workbenApi.delMemberScript(that.del_id).then(res => {
          that.loading=false
          if (res.code == 1000) {
            that.$succMsg(res.message)
            that.getList()
          } else {
            that.$errMsg(res.message)
          }
        })
      },
    },
	}
</script>

<style lang='scss' scoped>
	.my_book{
    width: 100%;
    margin-top: 25px;
    .model_nav{
      width: 100%;
      display: flex;
      .nav{
        cursor: pointer;
        position: relative;
        width: 104px;
        height: 50px;
        background: rgba(255,255,255,0.7);
        .name{
          display: block;
          width: 100%;
          line-height: 50px;
          text-align: center;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
        }
      }
      .nav_active{
        background: rgba(255,255,255,0.47);
        .name{
          color: #2E4BF2;
        }
        .xian{
          position: absolute;
          bottom: 0;
          left: 50%;
          display: block;
          width: 25px;
          height: 2px;
          margin-left: -13px;
          background: #2E4BF2;
        }
      }
      .nav:first-child{
        border-radius: 10px 0px 0px 0px;
      }
      .nav:last-child{
        border-radius: 0px 10px 0px 0px;
      }
    }
    /*  */
    .book_list{
      width: 100%;
      padding: 20px;
      border-radius: 0 10px 10px;
      background: rgba(255,255,255,0.47);
      .box_ss{
        width: 100%;
        padding: 10px 20px;
        background: rgba(255,255,255,0.79);
        border-radius: 4px;
        .box_top{
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .book_nav{
          display: flex;
          align-items: center;
          .nav{
            cursor: pointer;
            margin-right: 10px;
            padding: 0 10px;
            height: 28px;
            line-height: 26px;
            border-radius: 4px;
            border: 1px solid #2B4AFE;
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2E4BF2;
          }
          .nav_active{
            color: #fff;
            background: #2E4BF2;
          }
        }
        .right{
          display: flex;
          align-items: center;
          .t_input{
            width: 186px;
            margin-right: 6px;
          }
          .el-icon-plus{
            margin-right: 5px;
          }
        }
      }
      .next_list{
        margin-top: 10px;
        width: 100%;
        display: flex;
        .next_nav{
          cursor: pointer;
          margin-right: 10px;
          padding: 0 10px;
          height: 28px;
          line-height: 26px;
          border-radius: 4px;
          background: rgba(67,74,249,.1);
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #434af9;
        }
        .next_active{
          border: 1px solid #434af9;
        }
      }
      .book_info{
        width: 100%;
        display: flex;
        .left{
          min-width: 166px;
          .left_btn1{
            cursor: pointer;
            margin-top: 20px;
            width: 100%;
            height: 87px;
            border: 1px dashed #2E4BF2;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            img{
              display: block;
              width: 40px;
              height: 40px;
            }
            span{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2E4BF2;
            }
          }
          .left_btn2{
            cursor: pointer;
            margin-top: 20px;
            width: 100%;
            height: 87px;
            border: 1px solid #fff;
            background: #fff;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            img{
              display: block;
              width: 40px;
              height: 40px;
            }
            span{
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #2E4BF2;
            }
          }
        }
        .right{
          width: calc(100% - 166px);
          display: flex;
          flex-wrap: wrap;
          .list{
            margin-left: 20px;
            margin-right: 6px;
            margin-top: 20px;
            cursor: pointer;
            width: 207px;
            height: 325px;
            background: #fff;
            border-radius: 6px;
            border: 1px solid rgba(255,255,255,0);
            overflow: hidden;
            .img{
              position: relative;
              width: 100%;
              height: 245px;
              border-radius: 6px 6px 0 0;
              overflow: hidden;
              .icon{
                position: absolute;
                top: 0;
                left: 10px;
                width: 22px;
                height: 40px;
                img{
                  display: block;
                  width: 100%;
                  height: 100%;
                }
              }
              .icon2{
                position: absolute;
                top: 0;
                right: 0;
                width: 24px;
                height: 24px;
                background: rgba(255, 255, 255, 0.5);
                border-radius: 0 0 0 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                  display: block;
                  width: 14px;
                  height: 14px;
                }
              }
            }
            .list_info{
              width: 100%;
              padding: 0 10px;
              .info_name{
                padding-top: 10px;
                font-size: 14px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #333333;
              }
              .info_tip{
                padding-top: 4px;
                font-size: 12px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                color: #666666;
              }
              .info_btn{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                img{
                  display: block;
                  width: 16px;
                  height: 16px;
                  margin-left: 10px;
                }
              }
            }
          }
          .list:hover{
            box-shadow: 0px 2px 18px 0px rgba(157,189,255,0.5);
            border: 1px solid #2E4BF2;
          }
        }
      }
    }
	}
</style>
