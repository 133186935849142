<!-- @Author: Yu_Bo -->
<template>
	<div class='works_draw' v-loading='loading'>
		<div class='list_left' @click="createBtn">
			<img src="@/assets/images/work/btn_icon.png" alt="">
			<span>快速创建</span>
		</div>
		<div class='list_right scroll_bar_x'>
			<div class="list_no_data" v-if="worksList.length == 0">您还没有作品哦，快速创建一下吧～</div>
			<template v-else>
				<div class="right_text" v-for="(item, index) in worksList" :key='index'>
					<div class="t_image">
						<div class="image" v-if="item.production_file && item.production_file.length">
							<el-image style="width: 100%; height: 100%" :src="item.production_file[0]"
								fit="scale-down"></el-image>
						</div>
						<div class='right_top top_right_color_edit' v-if="item.status == 1">编辑中</div>
						<div class='right_top top_right_color_conflate' v-if="item.status == 2">排队中</div>
						<div class='right_top top_right_color_lineUp' v-if="item.status == 3">合成中</div>
						<div class='right_top top_right_color_fail' v-if="item.status == 5">创作失败</div>
					</div>
					<div class='t_info'>
						<div class="info_left">
							<div class="name">
								<div class="name_input" v-if="item.inputShow">
									<el-input :key="index" size="mini" :ref="'input' + index" :autofocus="true"
										v-model="item.name" @keyup.enter.native="$event.target.blur()"
										@blur='blurBtn($event, index)' placeholder="请输入视频名称"></el-input>
								</div>
								<div class='name_txt one-txt-cut' v-else>{{ item.name }}</div>
								<div class='name_icon' v-if="!item.inputShow" @click="nameInputBtn(index)"><i
										class="el-icon-edit-outline"></i></div>
							</div>
							<div class='time'>创建时间 {{ item.create_time }}</div>
						</div>
						<div class="info_right">
							<el-popover placement="bottom-end" width="145" trigger="hover" popper-class="works_right-btn">
								<div class="content">
									<div class="item" v-if="item.status == 4">查看详情</div>
									<div class="item" v-if="item.status == 4">复制链接</div>
									<div class="item" v-if="item.status == 4">下载</div>
									<div class="item c_e" @click="delBtn(item.id)">删除</div>
								</div>
								<i slot="reference" class="el-icon-more"></i>
							</el-popover>
						</div>
					</div>
				</div>
			</template>
		</div>
		<!--  -->
		<point-out ref='dialogTip' @close='closeBtn' type='works' dialogTitle="提示" dialogWidth='380px' :iconShow='true'
			iconColor='#FF7432' borderRadius='10px' :cancelShow='true' :affirmShow='true'>
			<div class="zc_dialog_tip_content">
				<div class="text">作品删除后不可恢复，请确认是否删除？</div>
			</div>
		</point-out>
	</div>
</template>

<script>
import PointOut from '@/components/point_out/point_out.vue'//温馨提示弹框
export default {
	components: { PointOut },
	props: {},
	data() {
		return {
			loading: false,
			worksList: [],
			//
			del_id: '',
		}
	},
	computed: {},
	watch: {},
	created() {
		this.getList()
	},
	mounted() { },
	methods: {
		// 列表
		getList() {
			var that = this
			that.loading = true
			that.$creationApi.getDrawList().then(res => {
				that.loading = false
				if (res.code == 1000) {
					if (res.result.total) {
						var arr = res.result.data
						arr.forEach(item => {
							item.inputShow = false
						})
						that.worksList = arr
					}
				} else {
					that.$errMsg(res.message)
				}
			})
		},
		// 修改名称
		nameInputBtn(index) {
			var that = this
			this.worksList.forEach((v, i) => {
				if (i == index) {
					v.inputShow = true
				} else {
					v.inputShow = false
				}
			})
			that.$nextTick(() => {
				let ref = eval('that.$refs.input' + index)[0]
				ref.focus()
			})
			this.$forceUpdate()
		},
		blurBtn(event, index) {
			var that = this
			that.worksList.forEach((v, i) => {
				v.inputShow = false
			})
			var params = {
				name: that.worksList[index].name
			}
			that.$creationApi.editDrawName(params, that.worksList[index].id).then(res => {
				if (res.code == 1000) {
					that.$succMsg(res.message)
					that.$forceUpdate()
				} else {
					that.$errMsg(res.message)
				}
			})
		},
		// 删除
		delBtn(val) {
			this.del_id = val
			this.$refs.dialogTip.openDialogBtn()
		},
		closeBtn() {
			var that = this
			that.$creationApi.delDraw(that.del_id).then(res => {
				if (res.code == 1000) {
					that.getList()
				} else {
					that.$errMsg(res.message)
				}
			})
		},
		// 创建
		createBtn() {
			this.$router.push('/workben/painting')
		},
	},
}
</script>

<style lang='scss' scoped>
.works_draw {
	width: 100%;
	padding-top: 20px;
	display: flex;

	.list_left {
		cursor: pointer;
		width: 224px;
		height: 162px;
		margin-right: 30px;
		background: rgba(66, 94, 255, 0.08);
		border-radius: 10px;
		border: 1px dashed #2E4BF2;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		img {
			display: block;
			width: 56px;
			height: 56px;
		}

		span {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #2E4BF2;
		}
	}

	.list_right {
		width: calc(100% - 254px);
		height: 230px;
		overflow-x: auto;
		display: flex;

		.list_no_data {
			width: 100%;
			height: 162px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.right_text {
			cursor: pointer;
			min-width: 286px;
			height: 100%;
			margin-right: 30px;

			.t_image {
				position: relative;
				width: 100%;
				height: 162px;
				border-radius: 10px;
				background: #111111;
				overflow: hidden;

				.image {
					position: relative;
					width: 100%;
					height: 100%;

					span {
						position: absolute;
						top: 0;
						left: 0;
						z-index: 1;
						display: block;
						width: 100%;
						height: 100%;
						background: rgba(0, 0, 0, 0.5);
						border-radius: 10px;
						overflow: hidden;
					}
				}

				.img_pro {
					position: absolute;
					top: 0;
					right: 0;
					z-index: 2;
					width: 100%;
					height: 100%;
					display: flex;
					align-items: center;
					justify-content: center;

					::v-deep .el-progress__text {
						font-size: 14px !important;
					}
				}

				.right_top {
					position: absolute;
					top: 0;
					right: 0;
					z-index: 1;
					height: 20px;
					padding: 0 5px;
					display: flex;
					align-items: center;
					justify-content: center;
					background: #FF7432;
					border-radius: 0px 10px 0px 10px;
					font-size: 12px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #FFFFFF;
				}

			}

			.t_info {
				width: 100%;
				height: 50px;
				display: flex;
				align-items: center;
				justify-content: space-between;

				.info_left {
					width: 250px;
					height: 50px;
					display: flex;
					align-items: center;
					justify-content: flex-end;
					flex-direction: column;

					.name {
						width: 100%;
						height: 28px;
						line-height: 28px;
						font-size: 14px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #333333;
						display: flex;
						align-items: center;

						.name_input {
							width: 230px;

							::v-deep .el-input__inner {
								padding: 0 10px;
								background: rgba(46, 75, 242, 0.06);
								border-radius: 4px;
								border: 1px solid #2E4BF2;
							}

							::-webkit-input-placeholder {
								/* WebKit, Blink, Edge */
								color: #666666;
							}

							:-moz-placeholder {
								/* Mozilla Firefox 4 to 18 */
								color: #666666;
							}

							::-moz-placeholder {
								/* Mozilla Firefox 19+ */
								color: #666666;
							}

							:-ms-input-placeholder {
								/* Internet Explorer 10-11 */
								color: #666666;
							}
						}

						.name_txt {
							max-width: 200px;
							font-size: 14px;
							font-family: PingFangSC-Regular, PingFang SC;
							font-weight: 400;
							color: #333333;
						}

						.name_icon {
							margin-left: 10px;
						}
					}

					.time {
						width: 100%;
						font-size: 12px;
						font-family: PingFangSC-Regular, PingFang SC;
						font-weight: 400;
						color: #666666;
					}
				}

				.info_right {
					height: 100%;
					width: 30px;
					display: flex;
					align-items: center;
					justify-content: center;

					.el-icon-more {
						font-size: 18px;
						color: #333333;
						transform: rotate(90deg);
					}
				}
			}
		}

		.right_text:last-child {
			margin-right: 20px;
		}
	}
}
</style>
