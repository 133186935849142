<!-- @Author: Yu_Bo -->
<template>
	<div class='nav_main'>
    <!-- 左边 -->
    <div class="main_left">
      <div class="mian_list" v-for="(item,index) in navLeft" :key="index" @click="navPath(item.path)">
        <div class="list_bg">
          <el-image style="width: 100%; height: 100%" :src="item.image" fit="cover"></el-image>
        </div>
        <div class="list_text">
          <div class="t_name">{{item.name}}</div>
          <div class="t_txt">{{item.text}}</div>
          <div class="t_btn">
            <i class="el-icon-right"></i>
          </div>
        </div>
        <div class="list_icon" v-if="item.icon">
          <img :src="item.icon" alt="">
        </div>
      </div>
    </div>
    <!-- 线 -->
		<div class="main_mild"><span></span></div>
    <!-- 右边 -->
		<div class="main_right">
      <div class="mian_list" v-for="(item,index) in navRight" :key="index">
        <div class="list_bg">
          <el-image style="width: 100%; height: 100%" :src="item.image" fit="cover"></el-image>
        </div>
        <div class="list_text">
          <div class="t_name">{{item.name}}</div>
          <div class="t_txt">{{item.text}}</div>
          <div class="t_btn">
            <i class="el-icon-right"></i>
          </div>
        </div>
        <div class="list_icon" v-if="item.icon">
          <img :src="item.icon" alt="">
        </div>
      </div>
    </div>
	</div>
</template>

<script>
	export default {
		components: {},
		props: {},
		data() {
			return{
        navLeft:[
          {image:require('@/assets/images/work/nav_bg1.png'),name:'数字分身',text:'定制您的专属数字人',path:'/workben/digital',icon:''},
          {image:require('@/assets/images/work/nav_bg2.png'),name:'声音克隆',text:'克隆您的专属声音',path:'/workben/sound',icon:''},
          {image:require('@/assets/images/work/nav_bg3.png'),name:'AI背景',text:'绘制您的专属画作',path:'/workben/painting',icon:''},
          {image:require('@/assets/images/work/nav_bg4.png'),name:'AI换脸',text:'绘制您的异次元形象',path:'/workben/comics',icon:require('@/assets/images/work/nav_icon.png')},
        ],
        navRight:[
          {image:require('@/assets/images/work/nav_bg5.png'),name:'开发者平台',text:'支持标准化API接口，快速集成',path:'',icon:''},
        ]
			}
		},
		computed: {},
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 跳转
      navPath(url){
        this.$router.push(url)
      },
    },
	}
</script>

<style lang='scss' scoped>
	.nav_main{
    width: 100%;
    height: 180px;
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .main_mild{
      width:1px;
      height: 100%;
      padding: 55px 0;
      margin: 0 64px;
      span{
        display: block;
        width: 1px;
        height: 100%;
        background: rgba(255,255,255,0.74);
      }
    }
    .main_right{
      width: 20%;
      height: 100%;
      .mian_list{
        cursor: pointer;
        position: relative;
        width: 100%;
        height: 100%;
        .list_bg{
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          border-radius: 20px;
          overflow: hidden;
        }
        .list_text{
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          padding: 24px 16px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .t_name{
            font-size: 30px;
            font-weight: normal;
            color: #FFFFFF;
          }
          .t_txt{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
          }
          .t_btn{
            margin-top: 15px;
            width: 22px;
            height: 22px;
            background: #FFFFFF;
            box-shadow: 0px 2px 20px 0px #413AFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            .el-icon-right{
              font-size: 14px;
              color: #2E4BF2;
              font-weight: bold;
            }
          }
        }
        .list_icon{
          position: absolute;
          top: 0;
          right: 0;
          z-index: 3;
          width: 62px;
          height: 30px;
          background: linear-gradient(90deg, #FF6060 0%, #FF9665 100%);
          border-radius: 0px 20px 0px 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            display: block;
            width: 41px;
            height: 12px;
          }
        }
      }
    }
    .main_left{
      flex: 1;
      height: 100%;
      display: flex;
      .mian_list{
        cursor: pointer;
        position: relative;
        flex: 1;
        height: 100%;
        margin-left: 26px;
        .list_bg{
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%;
          border-radius: 20px;
          border: 1px solid #fff;
          overflow: hidden;
        }
        .list_text{
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          width: 100%;
          height: 100%;
          padding: 24px 16px;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          .t_name{
            font-size: 30px;
            font-weight: normal;
            color: #FFFFFF;
          }
          .t_txt{
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
          }
          .t_btn{
            margin-top: 15px;
            width: 22px;
            height: 22px;
            background: #FFFFFF;
            box-shadow: 0px 2px 20px 0px #413AFF;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            .el-icon-right{
              font-size: 14px;
              color: #2E4BF2;
              font-weight: bold;
            }
          }
        }
        .list_icon{
          position: absolute;
          top: 0;
          right: 0;
          z-index: 3;
          width: 62px;
          height: 30px;
          background: linear-gradient(90deg, #FF6060 0%, #FF9665 100%);
          border-radius: 0px 20px 0px 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            display: block;
            width: 41px;
            height: 12px;
          }
        }
      }
      .mian_list:first-child{
        margin-left: 0;
      }
    }
	}
</style>
