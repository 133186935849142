<!-- @Author: Yu_Bo -->
<template>
	<div class='workben_property'>
    <!--  -->
    <div class="main_title">
      <div class="title_left">
        <div class="t_title">
          <span></span>我的数字资产
        </div>
        <div class="title_nav">
          <div class="nav_text" :class="navIndex==index?'nav_text_active':''" v-for="(item,index) in navList" :key="index" @click="navBtn(index)">
            <img v-if="navIndex==index" :src="item.selectedIconPath" alt="">
            <img v-else :src="item.iconPath" alt="">
            <span>{{item.name}}</span>
          </div>
          <div class="nav_xian" v-if="navIndex==0"><span></span></div>
          <div class="nav_input" v-if="navIndex==0">
            <el-input v-model="inputTxt" size='mini' @keyup.enter.native="modelListBtn" @clear='modelListBtn' :clearable="true" placeholder="请输入模特名称搜索"></el-input>
          </div>
        </div>
      </div>
      <div class="title_right">
        <div class="right_num" @click="modelBtn">
          <span>模特定制次数</span>
          <span style="font-size: 18px;padding: 0 5px;">{{userInfo.major_cust || 0}}</span>
          <span>次</span>
          <div class="num_btn">立即定制</div>
          <i class="el-icon-arrow-right"></i>
        </div>
        <el-popover popper-class='popovre_text_four' placement="bottom" width="220" trigger="hover">
          <div class="num_list">
            <div class="list_text">
              <div class="t_left">视频时长</div>
              <div class="t_right">
                <div v-if="$utils.formatSeconds(userInfo.video_duration, 3).h">
                    {{ $utils.formatSeconds(userInfo.video_duration, 3).h }}<span>小时</span></div>
                <div v-if="$utils.formatSeconds(userInfo.video_duration, 3).m">
                    {{ $utils.formatSeconds(userInfo.video_duration, 3).m }}<span>分钟</span></div>
                <div>{{ $utils.formatSeconds(userInfo.video_duration, 3).s }}<span>秒</span></div>
              </div>
            </div>
            <div class="list_text">
              <div class="t_left">音频时长</div>
              <div class="t_right">
                <div v-if="$utils.formatSeconds(userInfo.audio_duration, 3).h">
                    {{ $utils.formatSeconds(userInfo.audio_duration, 3).h }}<span>小时</span></div>
                <div v-if="$utils.formatSeconds(userInfo.audio_duration, 3).m">
                    {{ $utils.formatSeconds(userInfo.audio_duration, 3).m }}<span>分钟</span></div>
                <div>{{ $utils.formatSeconds(userInfo.audio_duration, 3).s }}<span>秒</span></div>
              </div>
            </div>
            <div class="list_text">
              <div class="t_left">AI绘画次数</div>
              <div class="t_right"><span>{{ userInfo.drawing || 0 }}</span>次</div>
            </div>
            <div class="list_text">
              <div class="t_left">钻石余额</div>
              <div class="t_right">
                <span>{{ userInfo.diamond || 0 }}</span>
                <img src="@/assets/images/icon/diamond_icon.png" alt="">
              </div>
            </div>
          </div>
          <div class="right_pov" slot="reference">
            <span>视频时长</span>
            <span style="font-size: 18px;">5</span>
            <span>分钟</span>
            <i class="el-icon-caret-bottom"></i>
          </div>
        </el-popover>
        <div class="right_btn" @click="rechargeBtn">
          <img src="@/assets/images/work/right_icon1.png" alt="">
          <span>充值</span>
        </div>
      </div>
    </div>
    <!--  -->
    <div class="main_list">
      <!-- 我的模特 -->
      <my-model ref="myModel" v-show="navIndex==0" :txt='inputTxt'></my-model>
      <!-- 我的模板 -->
      <my-template ref="template" v-show="navIndex==1"></my-template>
      <!-- 我的声音 -->
      <my-sound ref="sound" v-show="navIndex==2"></my-sound>
      <!-- 我的台本 -->
      <my-book ref="book" v-show="navIndex==3"></my-book>
    </div>
    <!-- 充值 -->
    <invest-money ref="invest_money" />
	</div>
</template>

<script>
  import { mapState } from "vuex"
  import MyModel from './my_model.vue'
  import MyTemplate from './my_template.vue'
  import MySound from './my_sound.vue'
  import MyBook from './my_book.vue'
  import InvestMoney from '@/components/invest_money/invest_money.vue'//充值
	export default {
		components: {
      MyModel,
      MyTemplate,
      MySound,
      MyBook,
      InvestMoney
    },
		props: {},
		data() {
			return{
        navIndex:0,
        navList:[
          {name:'我的模特',iconPath:require('@/assets/images/work/pro_icon_a.png'),selectedIconPath:require('@/assets/images/work/pro_icon_aHL.png')},
          {name:'我的模板',iconPath:require('@/assets/images/work/pro_icon_b.png'),selectedIconPath:require('@/assets/images/work/pro_icon_bHL.png')},
          {name:'我的声音',iconPath:require('@/assets/images/work/pro_icon_c.png'),selectedIconPath:require('@/assets/images/work/pro_icon_cHL.png')},
          {name:'我的台本',iconPath:require('@/assets/images/work/pro_icon_d.png'),selectedIconPath:require('@/assets/images/work/pro_icon_dHL.png')},
        ],
        inputTxt:'',
			}
		},
		computed: {
      ...mapState({
          userInfo: state => state.userInfo.user_info, // 用户信息
      }),
    },
		watch: {},
		created() {},
		mounted() {},
		methods: {
      // 切换
      navBtn(index){
        if(this.navIndex==index){
          return
        }else{
          this.$refs.template.pauseBtn()
          this.navIndex=index
          if(index==0){
            this.$refs.myModel.modelListBtn()
          }else if(index==1){
            this.$refs.template.templateListBtn()
          }else if(index==2){
            this.$refs.sound.soundListBtn()
          }else if(index==3){
            this.$refs.book.bookListBtn()
          }
        }
      },
      // 搜索
      modelListBtn(){
        this.$refs.myModel.modelListBtn()
      },
      // 立即定制
      modelBtn(){
        this.$router.push('/workben/digital')
      },
      // 充值
      rechargeBtn(){
        this.$refs.invest_money.openDialogBtn(0)
      },
    },
	}
</script>

<style lang='scss' scoped>
	.workben_property{
    width: 100%;
    margin-top: 20px;
    padding: 20px;
    background: rgba(255,255,255,0.3);
    border-radius: 10px;
    .main_title{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .title_left{
        display: flex;
        align-items: center;
        .t_title{
          display: flex;
          align-items: center;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          span{
            width: 5px;
            height: 18px;
            margin-right: 10px;
            background: #2E4BF2;
            border-radius: 3px;
          }
        }
        .title_nav{
          padding-left: 20px;
          display: flex;
          align-items: center;
          .nav_text{
            margin-right: 12px;
            cursor: pointer;
            height: 29px;
            padding: 0 10px;
            background: rgba(255,255,255,0.59);
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              display: block;
              width: 14px;
              height: 14px;
            }
            span{
              padding-left: 4px;
              font-size: 14px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #666666;
            }
          }
          .nav_text_active{
            background: rgba(66,94,255,0.1);
            span{
               color: #2E4BF2;
            }
          }
          .nav_xian{
            width: 1px;
            height: 29px;
            margin: 0 20px 0 8px;
            padding: 7px 0;
            span{
              display: block;
              width: 100%;
              height: 100%;
              background: #A8A8A8;
            }
          }
          .nav_input{
            width: 186px;
            ::v-deep .el-input{
              height: 29px;
              .el-input__inner{
                height: 29px;
                line-height: 29px;
              }
            }
          }
        }
      }
      .title_right{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .right_num{
          cursor: pointer;
          margin-right: 20px;
          padding: 0 18px;
          height: 38px;
          border-radius: 22px;
          background: rgba(255,255,255,0.59);
          display: flex;
          align-items: center;
          justify-content: center;
          span{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #666666;
          }
          .num_btn{
            margin-left: 15px;
            margin-right: 2px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #2E4BF2;
            text-decoration:underline;
          }
          .el-icon-arrow-right{
            font-size: 14px;
            margin-top: 2px;
            color: #666666;
          }
        }
        .right_pov{
          cursor: pointer;
          margin-right: 20px;
          padding: 0 18px;
          height: 38px;
          background: linear-gradient(90deg, #FF5F5F 0%, #FF7432 100%);
          border-radius: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          span{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #fff;
          }
          .el-icon-caret-bottom{
            color: #fff;
            margin-left: 5px;
          }
        }
        .right_btn{
          cursor: pointer;
          width: 78px;
          height: 38px;
          background: linear-gradient(90deg, #FF5F5F 0%, #FF7432 100%);
          border-radius: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          img{
            display: block;
            width: 24px;
            height: 24px;
          }
          span{
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
          }
        }
      }
    }
    .main_list{
      width: 100%;
      margin-top: 20px;
    }
	}
</style>
